var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("PatientInfoLayout", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ currentView }) {
          return [
            _vm.isProcessing
              ? _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-absolute tw-justify-center tw-items-center tw-inset-0",
                  },
                  [
                    _c("span", { staticClass: "tw-text-2xl" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                )
              : _c("b-container", { attrs: { fluid: "" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header-nav tw-relative md:tw-sticky md:tw-z-[1] tw-top-0 tw-py-3.5 tw-flex tw-justify-between tw-items-center tw-flex-none tw-px-4 md:tw-py-5",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex-none tw-flex tw-items-center tw-text-warning",
                        },
                        [
                          _c("span", {
                            staticClass: "back-arrow tw-mr-3 tw-cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.$router.back()
                              },
                            },
                          }),
                          _vm.previousRoute
                            ? _c("span", { staticClass: "tw-text-warning" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.previousRoute.meta.title) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      currentView === "prescription-history"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "tw-flex-none md:tw-flex md:tw-flex-wrap md:tw-items-center md:tw-justify-around md:tw-mt-0 md:tw-space-x-2",
                            },
                            [
                              !_vm.currentDoctor.id
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-hidden md:tw-block tw-space-x-2",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "sm",
                                            variant: "outline-danger",
                                            pill: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$bvModal.show(
                                                "cancelPatient"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel Patient ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "sm",
                                            variant: "outline-primary",
                                            pill: "",
                                            to: `/patients/${_vm.patientInfo.id}/intake`,
                                          },
                                        },
                                        [_vm._v(" View Intake History ")]
                                      ),
                                      _vm.patientInfo &&
                                      _vm.patientInfo.id &&
                                      !_vm.patientInfo.isbanned
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "md:tw-min-w-[150px]",
                                              attrs: {
                                                pill: "",
                                                size: "sm",
                                                variant: "outline-primary",
                                              },
                                              on: { click: _vm.onPrescribe },
                                            },
                                            [_vm._v(" Prescribe ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.currentDoctor.id
                                ? _c(
                                    "b-dropdown",
                                    {
                                      staticClass:
                                        "tw-block md:tw-hidden tw-mr-4",
                                      attrs: {
                                        variant: "link",
                                        "toggle-class": "text-decoration-none",
                                        "menu-class": "custom-dropdown-menu",
                                        "no-caret": "",
                                        right: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function () {
                                              return [
                                                _c("b-icon", {
                                                  attrs: {
                                                    icon: "three-dots-vertical",
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        374507881
                                      ),
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        { on: { click: _vm.onPrescribe } },
                                        [_vm._v(" Prescribe ")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            to: `/patients/${_vm.patientInfo.id}/intake`,
                                          },
                                        },
                                        [_vm._v(" View Intake History ")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            "link-class": "!tw-text-alert",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$bvModal.show(
                                                "cancelPatient"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel Patient ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  currentView === "reminders"
                    ? _c(
                        "div",
                        [
                          _c("reminder", {
                            attrs: { page: "patient-profile" },
                          }),
                        ],
                        1
                      )
                    : currentView === "prescription-history"
                    ? _c("div", [
                        _vm.patientInfo.isbanned
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "tw-bg-[#EB5757] tw-text-white tw-p-1 tw-text-center",
                              },
                              [
                                _vm._v(
                                  " Patient Banned - " +
                                    _vm._s(_vm.patientInfo.banned_reason) +
                                    " "
                                ),
                              ]
                            )
                          : _vm.patientInfo.cancelledbyusername
                          ? _c("div", { staticClass: "tw-px-4" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-bg-[#EB5757] tw-flex-1 tw-text-white tw-p-1 tw-text-center",
                                },
                                [
                                  _vm._v(
                                    " Canceled on " +
                                      _vm._s(
                                        _vm.parseDate(
                                          _vm.patientInfo
                                            .fullycancelleddatetime,
                                          "MM/dd/yyyy"
                                        )
                                      ) +
                                      " by " +
                                      _vm._s(
                                        _vm.patientInfo.cancelledbyusertype ===
                                          "DOCTOR"
                                          ? "Dr. "
                                          : ""
                                      ) +
                                      _vm._s(
                                        _vm.patientInfo.cancelledbyusername
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "tw-px-4 tw-py-5",
                            class: {
                              "tw-pointer-events-none tw-opacity-75":
                                _vm.patientInfo.cancelledbyusername,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.ongoingItems.length > 0,
                                    expression: "ongoingItems.length > 0",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-flex tw-flex-wrap tw-items-center mb-4 space-y-4 lg:space-y-0",
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "text-primary tw-text-lg mb-0 mr-3 tw-w-full lg:tw-w-auto",
                                      },
                                      [_vm._v(" Active Prescription ")]
                                    ),
                                    _vm.selectedFollowup
                                      ? [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "tw-text-[#BDBDBD] mr-2",
                                            },
                                            [_vm._v("Next Follow-up")]
                                          ),
                                          _c("p", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.parseDate(
                                                    _vm.selectedFollowup
                                                      .followupdatestamp,
                                                    "MMMM d, yyyy"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          !_vm.currentDoctor.id
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    size: "sm",
                                                    variant: "link",
                                                    pill: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$bvModal.show(
                                                        "followupupdate"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Reschedule ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c("section-card-prescription", {
                                  ref: "sectionCardPrescription",
                                  attrs: {
                                    items: _vm.ongoingItems,
                                    "is-add-refill-modal-open":
                                      _vm.isAddRefillModalOpen,
                                  },
                                  on: {
                                    "update:isAddRefillModalOpen": function (
                                      $event
                                    ) {
                                      _vm.isAddRefillModalOpen = $event
                                    },
                                    "update:is-add-refill-modal-open":
                                      function ($event) {
                                        _vm.isAddRefillModalOpen = $event
                                      },
                                    "refetch-prescriptions":
                                      _vm.cardRefetchPrescriptions,
                                    "refetch-single-prescription":
                                      _vm.cardRefetchSinglePrescription,
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.isBusy
                              ? _c("dashboard-table", {
                                  staticClass: "patient-profile-table",
                                  attrs: {
                                    "table-header": _vm.fields,
                                    "table-item": _vm.listViewItems,
                                    "scroll-height":
                                      _vm.ongoingItems.length > 0
                                        ? "250px"
                                        : "",
                                    scrollable: "",
                                    "has-search": "",
                                  },
                                  on: {
                                    prescribed: _vm.refetchPrescriptions,
                                    searchPrescription: _vm.searchPrescription,
                                    "poll-prescriptions":
                                      _vm.cardRefetchPrescriptions,
                                    "open-restart-treatment":
                                      _vm.onOpenRestartTreatmentModal,
                                    "open-add-refill": _vm.onOpenAddRefillModal,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : currentView === "followup"
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-flex tw-justify-between tw-items-center mt-5 mb-4",
                            },
                            [
                              _c("div", { staticClass: "text-primary" }, [
                                _c("h2", { staticClass: "mb-0" }, [
                                  _vm._v("Follow-up History"),
                                ]),
                              ]),
                              !_vm.patientInfo.followupdate
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "px-3",
                                      staticStyle: { "min-width": "150px" },
                                      attrs: {
                                        pill: "",
                                        size: "sm",
                                        variant: "outline-primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isScheduleFollowupOpen = true
                                        },
                                      },
                                    },
                                    [_vm._v(" Schedule a follow-up ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("table-followup", {
                            attrs: {
                              "schedule-followup": _vm.isScheduleFollowupOpen,
                              fields: _vm.followupFields,
                              items: _vm.followupItems,
                              "scroll-height": "calc(90vh - 250px)",
                              "no-header": true,
                              scrollable: "",
                            },
                            on: {
                              "update:scheduleFollowup": function ($event) {
                                _vm.isScheduleFollowupOpen = $event
                              },
                              "update:schedule-followup": function ($event) {
                                _vm.isScheduleFollowupOpen = $event
                              },
                              refetch: _vm.onFollowupRefetch,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
            _c("modal-restart-prescription", {
              attrs: { processing: _vm.isProcessing },
              on: { restart: _vm.onRestartTreatment },
            }),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "cancelPatient",
                  "hide-header": "",
                  centered: "",
                  "hide-footer": "",
                  size: "lg",
                },
                model: {
                  value: _vm.isCancelPatientModalOpen,
                  callback: function ($$v) {
                    _vm.isCancelPatientModalOpen = $$v
                  },
                  expression: "isCancelPatientModalOpen",
                },
              },
              [
                _c(
                  "b-row",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "b-col",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          left: "0",
                        },
                        attrs: { cols: "12", sm: "5" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "30",
                              height: "27",
                              viewBox: "0 0 30 27",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                                fill: "#1A6A72",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "py-6",
                        attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                      },
                      [
                        _c(
                          "h1",
                          {
                            staticClass: "heading mb-4",
                            staticStyle: { "line-height": "30px" },
                          },
                          [_vm._v("Cancel Patient")]
                        ),
                        _c("p", { staticClass: "mb-4" }, [
                          _vm._v(
                            " Are you sure you want to cancel this patient? This will cancel any outstanding refills, removes them from the three month follow up emails, and makes them non-active patients. "
                          ),
                        ]),
                        _c("ValidationObserver", {
                          ref: "notesForm",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ handleSubmit }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-4" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "Reason",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "cancel-field-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Why are you cancelling the patient? "
                                                        ),
                                                      ]
                                                    ),
                                                    _c("v-select", {
                                                      staticClass:
                                                        "cancel-selectbox",
                                                      attrs: {
                                                        placeholder:
                                                          "Select one",
                                                        options:
                                                          _vm.cancelReasons,
                                                      },
                                                      model: {
                                                        value: _vm.reason,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.reason = $$v
                                                        },
                                                        expression: "reason",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(errors[0]) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mb-4" },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "cancel-field-label" },
                                          [_vm._v(" Notes ")]
                                        ),
                                        _c("b-form-textarea", {
                                          staticClass: "px-0",
                                          attrs: {
                                            id: "textarea",
                                            rows: "3",
                                            "max-rows": "6",
                                          },
                                          model: {
                                            value: _vm.notes,
                                            callback: function ($$v) {
                                              _vm.notes = $$v
                                            },
                                            expression: "notes",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      { staticClass: "mb-3" },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  pill: "",
                                                  block: "",
                                                  variant: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$bvModal.hide(
                                                      "cancelPatient"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Nevermind ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  pill: "",
                                                  block: "",
                                                  disabled:
                                                    _vm.isCancellingPatient,
                                                  variant: "outline-danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onCancelPatient
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.isCancellingPatient
                                                  ? _c("b-spinner", {
                                                      attrs: { small: "" },
                                                    })
                                                  : _c("span", [
                                                      _vm._v("Confirm"),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("modal-set-reminder", { attrs: { patient: _vm.patientInfo } }),
            _c(
              "portal",
              { attrs: { to: "notes" } },
              [
                _c("ModalBodyNotes", {
                  attrs: { "show-notes-modal": _vm.showNotesModal },
                  on: {
                    "update:showNotesModal": function ($event) {
                      _vm.showNotesModal = $event
                    },
                    "update:show-notes-modal": function ($event) {
                      _vm.showNotesModal = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm.selectedFollowup
              ? _c("modal-update-followup", {
                  attrs: { "selected-followup": _vm.selectedFollowup },
                  on: { refetch: _vm.onFollowupRefetch },
                })
              : _vm._e(),
            _c("ModalDiscussTreatment", {
              on: {
                discuss: function ($event) {
                  return _vm.$router.push(
                    `/patients/${_vm.patientInfo.id}/message`
                  )
                },
              },
            }),
            _c("ModalPrescribeEstrogenAlert", {
              attrs: { processing: _vm.isProcessing },
              on: { prescribe: (payload) => _vm.onRestartTreatment(true) },
            }),
            _c("modal-prescribe-estrogen-restart", {
              attrs: { processing: _vm.isProcessing },
              on: { prescribe: () => _vm.onRestartTreatment(true) },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }