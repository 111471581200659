var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "followupupdate",
        "hide-header": "",
        centered: "",
        "hide-footer": "",
        size: "lg",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
    },
    [
      _c("ValidationObserver", {
        ref: "followupForm",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit }) {
              return [
                _c(
                  "b-row",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "b-col",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          left: "0",
                        },
                        attrs: { cols: "12", sm: "5" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "30",
                              height: "27",
                              viewBox: "0 0 30 27",
                              fill: "#1A6A72",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                                fill: "#1A6A72",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "py-6",
                        attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                      },
                      [
                        _c(
                          "h1",
                          {
                            staticClass: "heading mb-4",
                            staticStyle: { "line-height": "30px" },
                          },
                          [_vm._v(" Update follow-up date ")]
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mb-4" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "mb-4", attrs: { cols: "12" } },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "followup",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-primary",
                                                attrs: { for: "followup" },
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v("Schedule Follow-up"),
                                                ]),
                                              ]
                                            ),
                                            _c("v-select", {
                                              attrs: {
                                                id: "followup",
                                                "calculate-position":
                                                  _vm.withPopper,
                                                label: "text",
                                                options: _vm.followUpOptions,
                                                reduce: (followUpOptions) =>
                                                  followUpOptions.value,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "open-indicator",
                                                    fn: function ({
                                                      attributes,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-icon",
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                icon: "chevron-down",
                                                                variant:
                                                                  "primary",
                                                              },
                                                            },
                                                            "b-icon",
                                                            attributes,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value:
                                                  _vm.selectedFollowUpOption,
                                                callback: function ($$v) {
                                                  _vm.selectedFollowUpOption =
                                                    $$v
                                                },
                                                expression:
                                                  "selectedFollowUpOption",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "followup_date",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-primary",
                                                attrs: { for: "followup_date" },
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v("Follow-up Date"),
                                                ]),
                                              ]
                                            ),
                                            _c("b-form-datepicker", {
                                              attrs: {
                                                id: "followup_date",
                                                disabled:
                                                  _vm.selectedFollowUpOption !==
                                                  "Manual",
                                                "date-disabled-fn":
                                                  _vm.dateDisabled,
                                              },
                                              model: {
                                                value: _vm.followupDate,
                                                callback: function ($$v) {
                                                  _vm.followupDate = $$v
                                                },
                                                expression: "followupDate",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      pill: "",
                                      block: "",
                                      variant: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$bvModal.hide(
                                          "followupupdate"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      pill: "",
                                      block: "",
                                      disabled: _vm.isProcessing,
                                      variant: "outline-primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return handleSubmit(
                                          _vm.onFollowupUpdate
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.isProcessing
                                      ? _c("b-spinner", {
                                          attrs: { small: "" },
                                        })
                                      : _c("span", [_vm._v("Confirm")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }