<template>
  <b-modal
    id="followupupdate"
    hide-header
    centered
    hide-footer
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
  >
    <ValidationObserver ref="followupForm" v-slot="{ handleSubmit }">
      <b-row style="position: relative">
        <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="#1A6A72"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="8" offset-sm="2" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">
            Update follow-up date
          </h1>

          <b-row class="mb-4">
            <b-col cols="12" class="mb-4">
              <ValidationProvider
                name="followup"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="followup" class="text-primary">
                  <small>Schedule Follow-up</small>
                </label>
                <v-select
                  id="followup"
                  v-model="selectedFollowUpOption"
                  :calculate-position="withPopper"
                  label="text"
                  :options="followUpOptions"
                  :reduce="(followUpOptions) => followUpOptions.value"
                >
                  <template #open-indicator="{ attributes }">
                    <b-icon
                      v-bind="attributes"
                      icon="chevron-down"
                      variant="primary"
                    ></b-icon>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <b-col cols="12">
              <ValidationProvider
                name="followup_date"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="followup_date" class="text-primary">
                  <small>Follow-up Date</small>
                </label>
                <b-form-datepicker
                  id="followup_date"
                  :disabled="selectedFollowUpOption !== 'Manual'"
                  :date-disabled-fn="dateDisabled"
                  v-model="followupDate"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col cols="12" sm="6">
              <b-button
                pill
                block
                @click="$bvModal.hide('followupupdate')"
                variant="primary"
              >
                Cancel
              </b-button>
            </b-col>
            <b-col cols="12" sm="6">
              <b-button
                pill
                block
                :disabled="isProcessing"
                @click="handleSubmit(onFollowupUpdate)"
                variant="outline-primary"
              >
                <b-spinner v-if="isProcessing" small></b-spinner>
                <span v-else>Confirm</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import {
  format,
  parse,
  add,
  getUnixTime,
  fromUnixTime,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import popper from "@/assets/js/popper.js";
export default {
  mixins: [popper],
  props: {
    selectedFollowup: {
      type: [Object, String],
      default: () => ({}),
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isProcessing: false,
      followUpOptions: [
        { value: "3 Month", text: "3 Months" },
        { value: "1 Year", text: "1 Year" },
        { value: "Manual", text: "Manual" },
      ],
      selectedFollowUpOption: "Manual",
      followupDate: "",
    };
  },
  watch: {
    selectedFollowUpOption: {
      handler(val) {
        if (val === "3 Month") {
          this.followupDate = format(
            add(new Date(), { days: 3 * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else if (val === "1 Year") {
          this.followupDate = format(
            add(new Date(), { days: 13 * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else if (val === "Manual") {
          this.followupDate = format(
            add(new Date(), { days: 1 }),
            "yyyy-MM-dd"
          );
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.followupDate = fromUnixTime(this.selectedFollowup.followupdatestamp);
  },
  methods: {
    dateDisabled(ymd, date) {
      return new Date() > date;
    },
    async onFollowupUpdate() {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("patients/updatePatientFollowupSchedule", {
          patientid: this.$route.params.id,
          patientfollowupid: this.selectedFollowup.id,
          data: {
            followupdatestamp: getUnixTime(
              parse(
                this.getDateStringWithCurrentTime(this.followupDate),
                "yyyy-MM-dd H:m:s",
                new Date()
              )
            ),
          },
        });

        this.$emit("refetch");
        this.$bvModal.hide("followupupdate");
      } catch (e) {
        console.error(e);
      } finally {
        this.isProcessing = false;
        this.$nextTick(() => {
          this.$refs.followupForm.reset();
        });
      }
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
  },
};
</script>
