<template>
  <PatientInfoLayout v-slot="{ currentView }">
    <div
      v-if="isProcessing"
      class="tw-flex tw-absolute tw-justify-center tw-items-center tw-inset-0"
    >
      <span class="tw-text-2xl">Loading...</span>
    </div>
    <b-container fluid v-else>
      <div
        class="
          header-nav
          tw-relative
          md:tw-sticky md:tw-z-[1]
          tw-top-0
          tw-py-3.5
          tw-flex
          tw-justify-between
          tw-items-center
          tw-flex-none
          tw-px-4
          md:tw-py-5
        "
      >
        <div class="tw-flex-none tw-flex tw-items-center tw-text-warning">
          <span
            class="back-arrow tw-mr-3 tw-cursor-pointer"
            @click="$router.back()"
          ></span>
          <span class="tw-text-warning" v-if="previousRoute">
            {{ previousRoute.meta.title }}
          </span>
        </div>
        <div
          class="
            tw-flex-none
            md:tw-flex
            md:tw-flex-wrap
            md:tw-items-center
            md:tw-justify-around
            md:tw-mt-0
            md:tw-space-x-2
          "
          v-if="currentView === 'prescription-history'"
        >
          <div
            class="tw-hidden md:tw-block tw-space-x-2"
            v-if="!currentDoctor.id"
          >
            <b-button
              size="sm"
              variant="outline-danger"
              pill
              @click="$bvModal.show('cancelPatient')"
            >
              Cancel Patient
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              pill
              :to="`/patients/${patientInfo.id}/intake`"
            >
              View Intake History
            </b-button>
            <b-button
              v-if="patientInfo && patientInfo.id && !patientInfo.isbanned"
              class="md:tw-min-w-[150px]"
              pill
              size="sm"
              variant="outline-primary"
              @click="onPrescribe"
            >
              Prescribe
            </b-button>
          </div>
          <b-dropdown
            v-if="!currentDoctor.id"
            class="tw-block md:tw-hidden tw-mr-4"
            variant="link"
            toggle-class="text-decoration-none"
            menu-class="custom-dropdown-menu"
            no-caret
            right
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item @click="onPrescribe"> Prescribe </b-dropdown-item>
            <b-dropdown-item :to="`/patients/${patientInfo.id}/intake`">
              View Intake History
            </b-dropdown-item>
            <b-dropdown-item
              link-class="!tw-text-alert"
              @click="$bvModal.show('cancelPatient')"
            >
              Cancel Patient
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div v-if="currentView === 'reminders'">
        <reminder page="patient-profile" />
      </div>
      <div v-else-if="currentView === 'prescription-history'">
        <div
          v-if="patientInfo.isbanned"
          class="tw-bg-[#EB5757] tw-text-white tw-p-1 tw-text-center"
        >
          Patient Banned - {{ patientInfo.banned_reason }}
        </div>
        <div v-else-if="patientInfo.cancelledbyusername" class="tw-px-4">
          <div
            class="
              tw-bg-[#EB5757] tw-flex-1 tw-text-white tw-p-1 tw-text-center
            "
          >
            Canceled on
            {{ parseDate(patientInfo.fullycancelleddatetime, "MM/dd/yyyy") }} by
            {{ patientInfo.cancelledbyusertype === "DOCTOR" ? "Dr. " : ""
            }}{{ patientInfo.cancelledbyusername }}
          </div>
        </div>

        <div
          class="tw-px-4 tw-py-5"
          :class="{
            'tw-pointer-events-none tw-opacity-75':
              patientInfo.cancelledbyusername,
          }"
        >
          <div v-show="ongoingItems.length > 0">
            <div
              class="
                tw-flex tw-flex-wrap tw-items-center
                mb-4
                space-y-4
                lg:space-y-0
              "
            >
              <h2
                class="text-primary tw-text-lg mb-0 mr-3 tw-w-full lg:tw-w-auto"
              >
                Active Prescription
              </h2>
              <template v-if="selectedFollowup">
                <small class="tw-text-[#BDBDBD] mr-2">Next Follow-up</small>
                <p class="mb-0">
                  {{
                    parseDate(
                      selectedFollowup.followupdatestamp,
                      "MMMM d, yyyy"
                    )
                  }}
                </p>
                <b-button
                  size="sm"
                  class="mr-3"
                  variant="link"
                  pill
                  @click="$bvModal.show('followupupdate')"
                  v-if="!currentDoctor.id"
                >
                  Reschedule
                </b-button>
              </template>
            </div>
            <section-card-prescription
              ref="sectionCardPrescription"
              :items="ongoingItems"
              :is-add-refill-modal-open.sync="isAddRefillModalOpen"
              @refetch-prescriptions="cardRefetchPrescriptions"
              @refetch-single-prescription="cardRefetchSinglePrescription"
            ></section-card-prescription>
          </div>
          <dashboard-table
            :table-header="fields"
            :table-item="listViewItems"
            :scroll-height="ongoingItems.length > 0 ? '250px' : ''"
            scrollable
            has-search
            @prescribed="refetchPrescriptions"
            @searchPrescription="searchPrescription"
            @poll-prescriptions="cardRefetchPrescriptions"
            v-if="!isBusy"
            class="patient-profile-table"
            @open-restart-treatment="onOpenRestartTreatmentModal"
            @open-add-refill="onOpenAddRefillModal"
          />
        </div>
      </div>
      <div v-else-if="currentView === 'followup'">
        <div class="tw-flex tw-justify-between tw-items-center mt-5 mb-4">
          <div class="text-primary">
            <h2 class="mb-0">Follow-up History</h2>
          </div>
          <b-button
            v-if="!patientInfo.followupdate"
            pill
            size="sm"
            variant="outline-primary"
            class="px-3"
            style="min-width: 150px"
            @click="isScheduleFollowupOpen = true"
          >
            Schedule a follow-up
          </b-button>
        </div>
        <table-followup
          :schedule-followup.sync="isScheduleFollowupOpen"
          :fields="followupFields"
          :items="followupItems"
          scroll-height="calc(90vh - 250px)"
          :no-header="true"
          scrollable
          @refetch="onFollowupRefetch"
        />
      </div>
    </b-container>

    <modal-restart-prescription
      @restart="onRestartTreatment"
      :processing="isProcessing"
    />

    <b-modal
      id="cancelPatient"
      hide-header
      centered
      hide-footer
      size="lg"
      v-model="isCancelPatientModalOpen"
    >
      <b-row style="position: relative">
        <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="8" offset-sm="2" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">Cancel Patient</h1>
          <p class="mb-4">
            Are you sure you want to cancel this patient? This will cancel any
            outstanding refills, removes them from the three month follow up
            emails, and makes them non-active patients.
          </p>
          <ValidationObserver ref="notesForm" v-slot="{ handleSubmit }">
            <div class="mb-4">
              <ValidationProvider
                name="Reason"
                rules="required"
                v-slot="{ errors }"
              >
                <small class="cancel-field-label">
                  Why are you cancelling the patient?
                </small>
                <v-select
                  class="cancel-selectbox"
                  placeholder="Select one"
                  :options="cancelReasons"
                  v-model="reason"
                ></v-select>
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </ValidationProvider>
            </div>
            <div class="mb-4">
              <small class="cancel-field-label"> Notes </small>
              <b-form-textarea
                id="textarea"
                class="px-0"
                v-model="notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
            <b-row class="mb-3">
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  @click="$bvModal.hide('cancelPatient')"
                  variant="primary"
                >
                  Nevermind
                </b-button>
              </b-col>
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  :disabled="isCancellingPatient"
                  @click="handleSubmit(onCancelPatient)"
                  variant="outline-danger"
                >
                  <b-spinner v-if="isCancellingPatient" small></b-spinner>
                  <span v-else>Confirm</span>
                </b-button>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-modal>

    <modal-set-reminder :patient="patientInfo"></modal-set-reminder>

    <portal to="notes">
      <ModalBodyNotes :show-notes-modal.sync="showNotesModal" />
    </portal>

    <modal-update-followup
      v-if="selectedFollowup"
      :selected-followup="selectedFollowup"
      @refetch="onFollowupRefetch"
    />

    <ModalDiscussTreatment
      @discuss="$router.push(`/patients/${patientInfo.id}/message`)"
    />

    <ModalPrescribeEstrogenAlert
      :processing="isProcessing"
      @prescribe="(payload) => onRestartTreatment(true)"
    />

    <modal-prescribe-estrogen-restart
      :processing="isProcessing"
      @prescribe="() => onRestartTreatment(true)"
    />
  </PatientInfoLayout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { format, fromUnixTime, getUnixTime } from "date-fns";

import { DashboardService } from "@/services/dashboard.service.js";
import { PatientService } from "@/services/patient.service.js";

import dashboardTable from "@/components/partials/DashboardTable";
import ModalSetReminder from "@/components/reminder/ModalSetReminder.vue";
import SectionCardPrescription from "@/components/patients/SectionCardPrescription.vue";
import ModalBodyNotes from "@/components/patients/ModalBodyNotes.vue";
import TableFollowup from "@/components/patients/TableFollowup.vue";
import ModalUpdateFollowup from "@/components/patients/ModalUpdateFollowup.vue";

import Reminder from "@/components/dashboard/Reminder.vue";

import { nanoid } from "nanoid";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import PatientInfoLayout from "@/layouts/PatientInfoLayout.vue";
import ModalDiscussTreatment from "@/components/prescription/ModalDiscussTreatment.vue";
import ModalRestartPrescription from "@/components/patients/ModalRestartPrescription.vue";
import ModalPrescribeEstrogenRestart from "@/components/prescription/ModalPrescribeEstrogenRestart.vue";
import ModalPrescribeEstrogenAlert from "@/components/prescription/ModalPrescribeEstrogenAlert.vue";

export default {
  name: "patient-profile",
  components: {
    dashboardTable,
    ModalSetReminder,
    Reminder,
    SectionCardPrescription,
    ModalBodyNotes,
    TableFollowup,
    ModalUpdateFollowup,
    ValidationObserver,
    ValidationProvider,
    PatientInfoLayout,
    ModalDiscussTreatment,

    ModalRestartPrescription,
    ModalPrescribeEstrogenRestart,
    ModalPrescribeEstrogenAlert,
  },
  data() {
    return {
      isBusy: true,
      progress: 56,
      items: [],
      tempArrPrescriptions: [],
      max: 100,
      patientId: "",
      patientName: "",
      fields: [
        { key: "prescriptionstatus", label: "Status", sortable: true },
        { key: "datecreated", label: "Date", sortable: false },
        { key: "medicinename", label: "Drug Name", sortable: false },
        {
          key: "dosage",
          label: "Dosage (Dose, Frequency, Duration)",
          sortable: false,
        },
        { key: "unit", label: "Total Quantity", sortable: false },
        { key: "details", label: "Details", sortable: false },
        // { key: "actionOrder", label: "Action", sortable: false },
      ],
      isLoading: false,
      iframeHeight: "",
      isCancellingPatient: false,

      // currentView: "followup",

      cancelReasons: [],
      notes: "",
      reason: "",

      showNotesModal: false,

      followupFields: [
        { key: "followupstatus", label: "Status", sortable: false },
        { key: "formattedfollowupdate", label: "Date", sortable: false },
        { key: "type", label: "Type", sortable: false },
        { key: "action", label: "Action", sortable: false },
      ],

      isScheduleFollowupOpen: false,
      isEditing: false,
      isProcessing: false,
      editablePatientInformation: {
        firstname: "",
        lastname: "",
        birthday: "",
        phoneno: "",
        shippingaddress: {
          address1: "",
          address2: "",
          zipcode: "",
          city: "",
        },
      },
      patientInformationKey: "",

      processingItemsLength: 0,
      showIntakeHistory: false,
      refreshPrescriptionCounter: 0,
      hasWaitingBeforeCleanup: false,
      hasWaitingOnCleanup: false,
      isPolling: false,
      recursiveTimoutFunction: null,

      selectedFollowup: "",
      isCancelPatientModalOpen: false,

      showRestartTreatmentModal: false,
      selectedRestartPrescription: "",

      isAddRefillModalOpen: false,
    };
  },
  metaInfo() {
    return {
      title: this.websiteTitle,
    };
  },
  watch: {
    isEditing(val) {
      if (!val) {
        this.patientInformationKey = nanoid();
      }
    },
    followupSchedule(val) {
      const scheduledFollowup = val.find(
        (followup) => followup.followupstatus === "SCHEDULED"
      );

      if (scheduledFollowup) {
        this.selectedFollowup = scheduledFollowup;
      }
    },
    isCancelPatientModalOpen(val) {
      if (val) {
        DashboardService.getLookup("cancelreason").then(({ data }) => {
          this.cancelReasons = data.map((reason) => ({
            label: reason.name,
            code: reason.id,
          }));
        });
      }
    },
  },
  methods: {
    matchHeight() {
      let appHeight = window.innerHeight - 18;
      this.iframeHeight = appHeight;
    },
    async searchPrescription(filter) {
      this.items = [];
      if (filter != "") {
        let tempArray = [];
        let prescriptions = await this.$store.dispatch(
          "prescription/searchPrescriptionOrderPerPatient",
          {
            search: filter,
            patientid: this.patientId,
          }
        );
        for (let i = 0; i < prescriptions.length; i++) {
          let prescription = prescriptions[i];
          prescription.patientid = this.patientInfo.id;
          (prescription.prescriptionid = prescription.id),
            (prescription.name = `${this.patientInfo.firstname} ${this.patientInfo.lastname}`);
          if (prescription.prescriptionstatus == "DRAFTED") {
            prescription.actionOrder = ["Update", "Prescribe", "Delete"];
          } else if (prescription.prescriptionstatus == "FOR CONFIRMATION") {
            prescription.actionOrder = [
              "Update",
              "Send Confirmation",
              "Delete",
            ];
          } else if (
            prescription.prescriptionstatus == "PENDING CONFIRMATION"
          ) {
            prescription.actionOrder = ["Delete"];
          } else if (prescription.prescriptionstatus == "ACCEPTED") {
            prescription.actionOrder = ["Prescribe"];
          } else if (prescription.prescriptionstatus == "PRESCRIBED") {
            prescription.actionOrder = ["Cancel"];
          } else if (prescription.prescriptionstatus == "REFILL") {
            prescription.actionOrder = ["Refill", "Cancel"];
          } else if (prescription.prescriptionstatus == "COMPLETED") {
            prescription.actionOrder = ["Refill"];
          } else if (prescription.prescriptionstatus == "FOLLOW-UP") {
            prescription.actionOrder = ["Continue", "Stop"];
          } else if (prescription.prescriptionstatus == "DECLINED") {
            prescription.actionOrder = ["Delete"];
          } else if (prescription.prescriptionstatus == "FAILED") {
            prescription.actionOrder = ["Retry", "Cancel"];
          } else {
            prescription.actionOrder = "";
          }
          // prescription.actionOrder =
          //   prescription.prescriptionstatus == "DRAFTED"
          //     ? ["Update", "Prescribe", "Delete"]
          //     : "";
          tempArray.push(prescription);
        }
        Promise.all(tempArray).then((res) => {
          this.items = res;
        });
      } else {
        return await this.getPrescriptions("all");
      }
    },
    async refetchPrescriptions() {
      this.tempArrPrescriptions = [];
      await this.getPrescriptions("all");
    },
    async getPrescriptions(nextpage) {
      const res = await this.$store.dispatch(
        "prescription/getPrescriptionOrderPerPatient",
        {
          patientid: this.patientInfo.id,
          nextpage,
        }
      );
      this.tempArrPrescriptions = [];
      for (let i = 0; i < this.prescriptions.length; i++) {
        let prescription = this.prescriptions[i];
        prescription.patientid = this.patientInfo.id;
        (prescription.prescriptionid = prescription.id),
          (prescription.name = `${this.patientInfo.firstname} ${this.patientInfo.lastname}`);
        if (prescription.prescriptionstatus == "DRAFTED") {
          prescription.actionOrder = ["Update", "Prescribe", "Delete"];
        } else if (prescription.prescriptionstatus == "FOR CONFIRMATION") {
          prescription.actionOrder = ["Update", "Send Confirmation", "Delete"];
        } else if (prescription.prescriptionstatus == "PENDING CONFIRMATION") {
          prescription.actionOrder = ["Delete"];
        } else if (prescription.prescriptionstatus == "ACCEPTED") {
          prescription.actionOrder = ["Prescribe"];
        } else if (prescription.prescriptionstatus == "PRESCRIBED") {
          prescription.actionOrder = ["Cancel"];
        } else if (prescription.prescriptionstatus == "REFILL") {
          prescription.actionOrder = ["Refill", "Cancel"];
        } else if (prescription.prescriptionstatus == "COMPLETED") {
          prescription.actionOrder = ["Refill"];
        } else if (prescription.prescriptionstatus == "FOLLOW-UP") {
          prescription.actionOrder = ["Continue", "Stop"];
        } else if (prescription.prescriptionstatus == "DECLINED") {
          prescription.actionOrder = ["Delete"];
        } else if (prescription.prescriptionstatus == "FAILED") {
          prescription.actionOrder = ["Retry", "Cancel"];
        } else {
          prescription.actionOrder = "";
        }
        // prescription.actionOrder =
        //   prescription.prescriptionstatus == "DRAFTED"
        //     ? ["Update", "Prescribe", "Delete"]
        //     : "";
        this.tempArrPrescriptions.push(prescription);
      }
      this.nextPage = res.nextpage;
      this.items = this.tempArrPrescriptions;
      this.isBusy = false;
      return this.items;
    },
    async getSinglePrescription({ patientId, prescriptionOrderId }) {
      const res = await this.$store.dispatch(
        "prescription/getPrescriptionOrder",
        {
          patientId,
          prescriptionOrderId,
        }
      );
      return res;
    },
    async pollProcessingStatus(isRefill) {
      const items = this.items;
      const hasDelayUntilItems = items.filter(
        (item) => item.delayuntil && item.delayuntil >= getUnixTime(new Date())
      );

      if (hasDelayUntilItems.length === 0) {
        let hasWaitingItems = items.filter(
          (item) => item.prescriptionstatus === "WAITING"
        );
        if (hasWaitingItems.length > 0) {
          this.hasWaitingBeforeCleanup = true;
          this.cleanupWaitingInterval();
        }
      }

      if (!this.hasWaitingBeforeCleanup && hasDelayUntilItems.length > 0) {
        this.recursiveTimoutFunction = setTimeout(
          this.pollProcessingStatus,
          1000
        );
        this.isPolling = true;
      }
    },
    async cleanupWaitingInterval() {
      const hasWaitingItems = this.items.filter((item) =>
        ["WAITING", "DRAFTED", "PROCESSING"].includes(item.prescriptionstatus)
      );

      if (this.refreshPrescriptionCounter > 8) {
        this.refreshPrescriptionCounter = 0;
        this.hasWaitingOnCleanup = false;
        this.hasWaitingBeforeCleanup = false;
        this.isPolling = false;
        return;
      }

      if (hasWaitingItems.length > 0) {
        this.hasWaitingOnCleanup = true;
        await this.getPrescriptions("all");
      } else {
        this.refreshPrescriptionCounter = 0;
        this.hasWaitingOnCleanup = false;
        this.hasWaitingBeforeCleanup = false;
        this.isPolling = false;
      }
      this.refreshPrescriptionCounter += 1;
      if (this.hasWaitingOnCleanup) {
        setTimeout(this.cleanupWaitingInterval, 3000);
      }
    },
    async cardRefetchSinglePrescription(prescriptionid) {
      const updatedPrescription = await this.getSinglePrescription({
        patientId: this.patientId,
        prescriptionOrderId: prescriptionid,
      });
      let foundIndex = this.items.findIndex(
        (el) => el.id === updatedPrescription.id
      );
      if (foundIndex > -1) {
        this.items.splice(foundIndex, 1, updatedPrescription);

        if (
          !["PRESCRIBED", "FAILED"].includes(
            updatedPrescription.prescriptionstatus
          )
        ) {
          setTimeout(
            () => this.cardRefetchSinglePrescription(prescriptionid),
            3000
          );
        }
      }
    },
    cancelPollingProcessingStatus() {
      clearInterval(this.intervalFunction);
    },
    async onCancelPatient() {
      try {
        this.isCancellingPatient = true;
        await PatientService.cancelPatient(this.patientId, {
          reason: this.reason.label,
          notes: this.notes,
        });
        await this.refetchPrescriptions();
        this.$store.dispatch("patients/getPatientInfo", {
          patientId: this.patientId,
        });
        this.$bvModal.hide("cancelPatient");
      } catch (e) {
        console.error(e);
      } finally {
        this.isCancellingPatient = false;
      }
    },
    async cardRefetchPrescriptions(arg = {}) {
      const { type = "", done = null, refill = false } = arg;
      await this.getPrescriptions("all");
      // To prevent 2 or more recursive api calls
      if (!this.isPolling) {
        this.pollProcessingStatus(refill);
      }

      if (done) done();
    },
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    onFollowupRefetch() {
      this.$store.dispatch("patients/getPatientInfo", {
        patientId: this.patientId,
        isRefetch: true,
      });
      this.getPrescriptions("all");
    },
    onPrescribe() {
      if (
        this.patientInfo.letdoctorchoose &&
        this.patientInfo.pendingdoctormessage
      ) {
        this.$bvModal.show("discussPrescription");
        return;
      }

      this.$router.push(`/prescriptions/order/${this.patientInfo.id}`);
    },
    onOpenRestartTreatmentModal(prescriptionid) {
      this.$bvModal.show("restartPrescription");
      this.selectedRestartPrescription = prescriptionid;
    },
    async onRestartTreatment(force) {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("prescription/restartTreatment", {
          patientid: this.patientInfo.id,
          prescriptionid: this.selectedRestartPrescription,
          payload: force ? { force: true } : null,
        });
        this.$bvModal.hide("restartPrescription");
        this.$bvModal.hide("prescribe-estrogen-restart-alert");
        this.$bvModal.hide("prescribe-estrogen-alert");

        this.isProcessing = false;
        setTimeout(async () => {
          await this.getPrescriptions("all");
          this.pollProcessingStatus();
        }, 3000);
      } catch (e) {
        this.isProcessing = false;
        this.$bvModal.hide("restartPrescription");
        this.$bvModal.hide("prescribe-estrogen-restart-alert");
        this.$bvModal.hide("prescribe-estrogen-alert");

        if (
          e?.message?.includes(
            "Patient has a Progesterone prescription cancelled by Stripe within the last 30 days"
          )
        ) {
          this.$bvModal.show("prescribe-estrogen-restart-alert");
        } else if (
          e?.message?.includes(
            "You are prescribing Estrogen to a patient that has not been prescribed Progesterone"
          )
        ) {
          this.$bvModal.show("prescribe-estrogen-alert");
        }
      }
    },
    onOpenAddRefillModal(prescription) {
      this.isAddRefillModalOpen = true;
      this.$refs.sectionCardPrescription.selectedPrescription = prescription;
    },
  },
  computed: {
    value() {
      return ((this.progress / this.max) * this.max).toFixed(0);
    },
    ongoingItems() {
      return this.items.filter(
        (item) =>
          !["REFILLED", "CANCELLED", "COMPLETED"].includes(
            item.prescriptionstatus
          ) && !(item.refills === item.paidrefills && item.refilluponrequest)
      );
    },
    listViewItems() {
      return this.items.filter(
        (item) =>
          [
            "REFILLED",
            "CANCELLED",
            "PRESCRIBED",
            "PAUSED",
            "PROCESSING",
            "SCHEDULED",
            "COMPLETED",
          ].includes(item.prescriptionstatus) ||
          (item.refills === item.paidrefills && item.refilluponrequest)
      );
    },
    websiteTitle() {
      const fullName = `${this.patientInfo.firstname} ${this.patientInfo.lastname}`;
      if (
        fullName &&
        (this.patientInfo.firstname || this.patientInfo.lastname)
      ) {
        return `Winona | ${fullName}`;
      } else {
        return `Winona | Patient Profile`;
      }
    },
    followupItems() {
      return this.followupSchedule.map((item) => ({
        ...item,
        formattedfollowupdate: this.parseDate(
          item.followupdatestamp,
          "MMMM d, yyyy"
        ),
      }));
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
      prescriptions: "prescription/getPrescriptionOrderPerPatient",
      previousRoute: "globals/previousRoute",
    }),
    ...mapState("patients", ["followupSchedule"]),
  },
  async mounted() {
    window.analytics.page();
    this.isProcessing = true;
    this.patientId = this.$route.params.id;
    this.matchHeight();

    this.patientInformationKey = nanoid();

    if (this.currentDoctor.id) {
      this.followupFields.pop();
    }

    window.addEventListener("resize", () => {
      this.matchHeight();
    });
    await this.$store.dispatch("patients/getPatientInfo", {
      patientId: this.patientId,
    });
    await this.getPrescriptions("all");
    this.pollProcessingStatus();

    this.isProcessing = false;
  },
  destroyed() {
    clearTimeout(this.recursiveTimoutFunction);
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>

<style>
.darkmode .cancel-selectbox .vs__search {
  color: white;
}

.custom-dropdown-menu {
  width: 170px !important;
}
</style>

<style scoped>
/deep/.table thead th,
/deep/.table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  background-color: rgb(250 251 252);
}
</style>

<style lang="scss" scoped>
.text-muted {
  color: #4f4f4f !important;
}

.content {
  > div {
    padding: 20px;
    background-color: rgba($primary, 0.05);
  }
}

.patient-info {
  .card-header {
    border-bottom: 0;
  }

  .card-body {
    height: calc(100vh - 350px);
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .patient-info {
    .card-body {
      height: auto;
    }
  }
}

.patient-info .card-body {
  @include media-breakpoint-up(md) {
    height: calc(100vh - 48vh);
  }
}

.cancel-field-label {
  color: #828282;
}

.header-nav,
.action-btn {
  background-color: rgba(#fff, 0.85);
}

.darkmode .header-nav,
.darkmode .action-btn {
  background-color: rgba(#121212, 0.85);
}
</style>
